import {Node} from '../../../features/node-service/models/node';
import {Type} from 'class-transformer';

export enum AckStatus {
  ACK,
  NO_ACK,
  ERROR,
  PENDING
}

export enum Action {
  CREATE,
  EDIT,
  DELETE
}

export class LogConfig {
  public id: number;

  public entity: string;

  public entityId: number;

  public eventUsername: string;

  public action: keyof typeof Action;

  public eventDate: Date;

  public ackDate: Date;

  public ackStatus: keyof typeof AckStatus;

  public ackUsername: string;

  @Type(() => Node)
  public targetNodes: Node[];
}
