import { NgModule } from '@angular/core';
import { GridSyncfusionComponent } from './grid-syncfusion.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { GridAllModule, SelectionService, SearchService, ColumnChooserService, ResizeService, ColumnMenuService, ReorderService, EditService, ExcelExportService } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { CommonModule } from '@angular/common';
import { RendererComponent } from './renderer.component';

@NgModule({
  declarations: [GridSyncfusionComponent, RendererComponent],
  imports: [
    GridAllModule,
    ReactiveFormsModule,
    FormsModule,
    NumericTextBoxAllModule,
    CommonModule,
  ],
  exports: [GridSyncfusionComponent],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    SelectionService,
    SearchService,
    ColumnChooserService,
    ResizeService,
    ColumnMenuService,
    ReorderService,
    EditService,
    ExcelExportService
  ]
})
export class GridSyncfusionModule { }
