import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

// EJ2
import {ProgressButtonModule} from '@syncfusion/ej2-angular-splitbuttons';
import {ButtonModule, CheckBoxModule} from '@syncfusion/ej2-angular-buttons';
import {TextBoxAllModule} from '@syncfusion/ej2-angular-inputs';

import {LoginPage} from './pages/login/login.page';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {LogoutPage} from './pages/logout/logout.page';
import {ApplicationModule} from '../application/application.module';

@NgModule({
  declarations: [
    LoginPage,
    LoginFormComponent,
    LogoutPage
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressButtonModule,
    CheckBoxModule,
    ButtonModule,
    TextBoxAllModule,
    ApplicationModule
  ]
})
export class AuthentificationModule { }
