import {Inject, Injectable} from '@angular/core';

import {deserialize, serialize} from 'serializr';
import {User} from './models/user';
import {StoreInterface} from '../admin/models/tools/store.interface';
import {LocalStorageService} from '@tlgpro/util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly tokenKey = 'access_token';
  readonly userKey = 'user';

  constructor(
    @Inject('store') private store: StoreInterface,
    private localStorageService: LocalStorageService
  ) {

    const token = this.localStorageService.getItem(this.tokenKey);
    const userJson = this.localStorageService.getItem(this.userKey);

    let user: User | null = null;
    if (userJson) {
      user = serialize(deserialize(User, JSON.parse(userJson)));
    }

    this.store.dispatch({
      accessToken: token,
      user,
      isAuthenticated: !!token
    });

  }

  login(token: string): void {
    this.localStorageService.setItem(this.tokenKey, token);

    this.store.dispatch({
      accessToken: token,
      isAuthenticated: true
    });
  }

  setUser(user: User): void {
    this.localStorageService.setItem(this.userKey, JSON.stringify(serialize(user)));

    this.store.dispatch({
      user: serialize(user)
    });
  }

  logout(): void {

    this.localStorageService.removeItem(this.tokenKey);
    this.localStorageService.removeItem(this.userKey);

    this.store.dispatch({
      accessToken: null,
      user: null,
      isAuthenticated: false
    });
  }

  refreshToken(token: string): void {

    this.localStorageService.setItem(this.tokenKey, token);

    this.store.dispatch({
      accessToken: token
    });
  }

}
