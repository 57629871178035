import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-login-form',
  template: `
    <section class="">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="shadow-inner divide-y w-full bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0">
          <div class="m-6 flex justify-center">
            <app-logo-application [loginDisplay]="true" [enableLink]="false"></app-logo-application>
          </div>
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8 overflow-hidden">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="space-y-4 md:space-y-6">
              <div class="group">
                <label for="username"
                       class="block mb-2 text-sm font-medium text-gray-900 group-focus-within:text-blue-600"
                       i18n="@@authentification.login.username">Nom d'utilisateur</label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i class="fa-solid fa-user group-focus-within:text-blue-600"></i>
                  </div>
                  <input type="text" id="username" formControlName="username"
                         class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-[10px] pl-10 focus:outline-blue-600"
                         required>
                </div>
              </div>
              <div class="group">
                <label for="password"
                       class="block mb-2 text-sm font-medium text-gray-900 group-focus-within:text-blue-600"
                       i18n="@@authentification.login.password">Mot de passe</label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i class="fa-solid fa-key fa-lg group-focus-within:text-blue-600"></i>
                  </div>
                  <input type="password" id="password" formControlName="password"
                         class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-[10px] pl-10 focus:outline-blue-600"
                         required>
                </div>
              </div>
              <div class="w-100 h-100 flex items-center justify-between">
                <div class="flex items-start">
                  <div class="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox" formControlName="remember"
                           class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300">
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="remember" class="text-gray-500"
                           i18n="@@authentification.login.remember">Se souvenir de mon identifiant</label>
                  </div>
                </div>
              </div>
              <button
                      class="z-50 inline-flex justify-center items-center
                      w-full py-2 text-white rounded-lg font-semibold shadow
                      transition-all bg-size-200 bg-pos-0
                      focus:ring-4 focus:outline-none focus:ring-blue-300
                      hover:bg-pos-100 hover:shadow-md"
                      [ngClass]="{'bg-gradient-to-tl from-blue-800 via-blue-600 to-blue-400 duration-300': form.valid,
                                  'cursor-default bg-gray-500 focus:ring-0': !form.valid,
                                  'bg-pos-100 shadow-md': submitted}">
                <i class="fa-solid fa-circle-notch fa-spin fa-lg mr-2"
                   [ngClass]="{'w-0 !hidden': !submitted, 'w-auto block': submitted}"></i><ng-container i18n="@@authentification.login.button">Connexion</ng-container>
              </button>
              <div class="duration-500 transition-[height,opacity]"
                   [ngClass]="{'h-0 opacity-0 !mt-0': !connexionDenied, 'h-full opacity-100 mt-3': connexionDenied}">
                <div class="flex items-center p-3 bg-red-100 rounded-b-lg border-t-4 border-red-500 text-red-700"
                     role="alert">
                  <i class="fa-solid fa-circle-exclamation"></i>
                  <div class="ml-3 text-sm font-medium">
                    <h3 i18n="@@authentification.login.connectionRefused">Connexion refusée</h3>
                    <p class="font-normal" i18n="@@authentification.login.incorrectCredentials">Les identifiants saisis sont incorrects.</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="z-50 h-[40px] flex items-center justify-end pr-3">
            <i>Powered by <a href="https://tlgpro.fr" class="logo-tlgpro hover:underline" target="_blank">TLG
              Pro</a></i>
          </div>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {

  @Input() form: FormGroup;
  @Output() formSubmit = new EventEmitter<{ loginComponent?: LoginFormComponent }>();

  public submitted = false;
  public connexionDenied = false;

  get username(): AbstractControl | null {
    return this.form.get('username');
  }

  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  constructor() {
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.submitted = true;

    this.formSubmit.emit({loginComponent: this});
  }

}
