import {Type} from 'class-transformer';
import {Service} from './service';

export class Node {
  id: number;

  name: string;

  @Type(() => Service)
  services: Service[];
}
