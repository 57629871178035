import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ApplicationRoutingModule} from './application-routing.module';
import {ApplicationComponent} from './pages/application/application.page';
import {LayoutModule} from '@tlgpro/layout';
import {BreadcrumbComponent} from './components/header/breadcrumb/breadcrumb.component';
import {LogConfigModule} from '../../ui/log-config/log-config.module';
import {LogoApplicationComponent} from './components/logo-application/logo-application.component';

@NgModule({
  declarations: [
    ApplicationComponent,
    BreadcrumbComponent,
    LogoApplicationComponent
  ],
  imports: [
    CommonModule,
    ApplicationRoutingModule,
    LayoutModule,
    LogConfigModule,
  ],
  exports: [
    LogoApplicationComponent
  ],
  providers: []
})
export class ApplicationModule {
}
