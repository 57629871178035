import { AdaptorOptions, DataManager, DataOptions, Query } from '@syncfusion/ej2-data';

import { ApiAdaptor } from './adaptors/api-adaptor';
import { ApiServiceInterface } from './apiService.interface';

export class ApiDataManager extends DataManager {

  constructor(api: ApiServiceInterface, dataSource?: DataOptions, query?: Query, adaptor?: AdaptorOptions | string) {

    if (dataSource) {

      const url = dataSource.url;
      if (url) {
        dataSource.insertUrl = dataSource.insertUrl || `${url}/create`;
        dataSource.updateUrl = dataSource.updateUrl || `${url}/update`;
        dataSource.removeUrl = dataSource.removeUrl || `${url}/delete`;
        dataSource.batchUrl = dataSource.batchUrl || `${url}/batch`;
      }

      dataSource.key = dataSource.key || 'id';
      dataSource.adaptor = dataSource.adaptor || new ApiAdaptor();

      if (dataSource.adaptor) {
        const processResponseFn = dataSource.adaptor.processResponse;
        // tslint:disable-next-line: no-any
        dataSource.adaptor.processResponse = (resp: any) => {

          if (resp.success === false) {
            if (resp.error) {
              api.onError(resp.error.message, resp.error.code);
            } else {
              api.onError(resp.status.message, resp.status.code);
            }
          }

          if (processResponseFn) {
            return processResponseFn(resp);
          }
        };
      }

    }

    super(dataSource, query, adaptor);

  }

}
