import {Component, OnInit} from '@angular/core';
import {detect} from 'detect-browser';
import {LogService} from '@core/log';
import {BrowserCompatibility} from '../../../../../supportedBrowsers';

@Component({
  selector: 'app-browser-compatibility',
  template: `
    <div *ngIf="!browserSupported && showMsg" class="z-50 absolute flex items-center bg-orange-600 py-1 px-4 w-screen">
      <span class="text-white text-center font-semibold mr-2 flex-auto" i18n="@@browser-compatibility.message">
        Cette application n'est pas optimisée pour votre navigateur
      </span>
      <div class="ml-3">
        <button class="text-white p-2 hover:bg-orange-500"
                title="Fermer ce message"
                i18n-title="@@browser-compatibility.btn.close"
                (click)="hide()"><i class="fas fa-times fa-lg"></i>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./browser-compatibility.component.css']
})
export class BrowserCompatibilityComponent implements OnInit {
  public detectTool = detect();
  public browserSupported: boolean;
  public showMsg = true;

  constructor(private logger: LogService) {
  }

  ngOnInit(): void {
    this.browserSupported = BrowserCompatibility.regex.test(navigator.userAgent);
    const browser = detect();

    if (!this.browserSupported) {
      this.logger.getDefaultLogger().warn(`Cette application n'est pas optimisée pour votre navigateur (${browser?.name} version ${browser?.version})`);
    } else {
      this.logger.getDefaultLogger().debug(`Cette application est optimisée pour votre navigateur (${browser?.name} version ${browser?.version})`);
    }
  }

  public hide(): void {
    this.showMsg = false;
  }
}
