import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {ColumnModel as TreeColumnModel, TreeGrid} from '@syncfusion/ej2-angular-treegrid';
import {ColumnModel} from '@syncfusion/ej2-angular-grids';
import {DataManager, Query, ReturnOption} from '@syncfusion/ej2-data';
import {GridSyncfusionComponent} from '@tlgpro/grid-syncfusion';
import {EnvironmentData} from '../../models/tools/environment-data';
import {ApiService, SyncfusionSpringAdaptor} from '@tlgpro/api-syncfusion';
import {ParamProfil} from '../../models/param-profil';
import {TreeParamProfil} from '../../models/tree-param-profil';
import {RawProfil} from '../../models/raw-profil';
import {FormEditData} from '../../models/form-edit-data';


class ParamsProfilAdaptor extends SyncfusionSpringAdaptor {
  hiddenData: Array<ParamProfil> = new Array<ParamProfil>();

  public processResponse = (data: any): object => {
    data = super.processResponse(data) as ParamProfil[];
    const authorities: TreeParamProfil[] = [];
    this.hiddenData = new Array<ParamProfil>();

    if (data) {
      for (const authority of data) {
        let parent: string | null = null;
        const nameLevels = authority.name.split('.');

        if (nameLevels.length > 1) {
          nameLevels.pop();
          parent = nameLevels.join('.');
        }

        const treeParamProfil: TreeParamProfil = authority as TreeParamProfil;

        if (parent) {
          treeParamProfil.parent = parent;
        } else {
          treeParamProfil.isRootNode = true;
        }

        authorities.push(treeParamProfil);
      }
    }

    return {
      // tslint:disable-next-line: no-non-null-assertion
      authorities: authorities.sort((a, b) => a.name!.localeCompare(b.name!))
    };
  }

  getHiddenData = (): ParamProfil[] => {
    return this.hiddenData;
  }
}

class ProfilsAdaptor extends SyncfusionSpringAdaptor {
  newParamsProfil: Map<string, ParamProfil> = new Map<string, ParamProfil>();
  newAuthorities: { id: number }[] = [];

  insert = (dm: DataManager, payload: RawProfil, tableName: string, query: Query): object => {
    return super.insert(dm, this.getPayloadWithParams(payload), tableName, query);
  }

  update = (dm: DataManager, keyField: string, payload: RawProfil, tableName: string, query: Query): object => {
    return super.update(dm, keyField, this.getPayloadWithParams(payload), tableName, query);
  }

  private getPayloadWithParams = (payload: RawProfil): RawProfil => {
    payload.authorities = this.newAuthorities;
    return payload;
  }

  setNewParamsProfil(records: (TreeParamProfil | ParamProfil)[]): void {
    this.newAuthorities = [];

    records.forEach((authority: TreeParamProfil | ParamProfil) => {
      const auth = authority as TreeParamProfil;
      if (auth.checkboxState !== undefined && auth.checkboxState === 'check') {
        this.newAuthorities.push({id: authority.id});
      }
    });
  }
}

@Component({
  template: `
    <div class="d-flex flex-column h-100">
      <h1 class="text-center"><i class="fas fa-users"></i><ng-container i18n="@@administration.page.profils">Paramétrage des rôles</ng-container></h1>
      <div class="overflow-auto flex-grow-1">
        <ng-template #editTemplate>
          <div>
            <div class="d-flex flex-column">
              <div *ngIf="grid.getColumnByUid('id').visible" class="form-group col-md-6">
                <div class="e-float-input e-control-wrapper">
                  <input id="id" name="id" type="text" [ngModel]="formData.id" disabled>
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="id" i18n="@@administration.page.profils.id">ID</label>
                </div>
              </div>
              <div>
                <div class="e-float-input e-control-wrapper">
                  <input id="name" name="name" type="text" [(ngModel)]="formData.name">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="name" i18n="@@administration.page.profils.name">Nom</label>
                </div>
              </div>
              <!-- <div class="form-group col-md-6">
                <div class="e-float-input e-control-wrapper">
                  <input id="isAdmin" name="isAdmin" type="hidden" [(ngModel)]="formData.isAdmin">
                  <ejs-checkbox label="Administrateur"
                    [ngModel]="formData.isAdmin === 1"
                    (ngModelChange)="formData.isAdmin = $event ? 1 : 0">
                  </ejs-checkbox>
                </div>
              </div> -->
              <div>
                <div class="e-control-wrapper">
                  <div class="label-droits">
                    <label for="paramsProfil" i18n="@@administration.page.profils.authorities">Gestion des autorisations</label>
                  </div>
                  <ejs-treegrid #treeGrid class="hide-header" name="paramsProfil" [columns]="columnsProfils" gridLines="None" height="500"
                                parentIdMapping="parent" idMapping="name" (load)="treeGrid.showSpinner()"
                                [dataSource]="dmAuthorities" [treeColumnIndex]="1"
                                (dataBound)="dataBound()">
                  </ejs-treegrid>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <tlgpro-grid-syncfusion #grid [editSettingsTemplate]="editTemplate" [pageSettings]="{ pageSize: 250 }" [dataSource]="data" [columns]="columns" dialogWidth="700"
                                [allowGrouping]="false" (actionFailure)="actionFailure($event)" (beginAdd)="getFormData(null)" (beginEdit)="getFormData($event)" (beforeSave)="beforeSave()">
        </tlgpro-grid-syncfusion>
      </div>
    </div>
  `,
  styleUrls: ['./profils.page.css', '../../admin.css']
})
// tslint:disable-next-line: component-class-suffix
export class ProfilsPage implements OnInit {
  @ViewChild('grid') grid: GridSyncfusionComponent;
  @ViewChild('treeGrid') treeGrid: TreeGrid;
  query: Query = new Query();

  customsRenderers: Map<string, NgModel> = new Map<string, NgModel>();
  data: DataManager;
  dataProfils: DataManager;
  paramsProfilAdaptor = new ParamsProfilAdaptor();
  profilsAdaptor = new ProfilsAdaptor();

  formDataReady = false;
  formData: FormEditData = {};

  dmAuthorities = new DataManager([]);

  columns: ColumnModel[] = [
    {uid: 'id', field: 'id', headerText: $localize`:@@administration.page.profils.id:ID`, width: 30, isPrimaryKey: true, visible: false},
    {uid: 'name', field: 'name', headerText: $localize`:@@administration.page.profils.name:Nom`, allowFiltering: true, allowSorting: true, validationRules: {required: true}}
  ];

  columnsProfils: TreeColumnModel[] = [
    {field: 'id', visible: false},
    {field: 'libelle', showCheckbox: true}
  ];

  constructor(@Inject('env') private environment: EnvironmentData,
              private api: ApiService) {
  }

  ngOnInit(): void {
    this.data = this.api.getDataManager({
      url: `${this.environment.api.prefix}/role`,
      adaptor: this.profilsAdaptor
    }, undefined, undefined, this.failureCallback);

    this.dataProfils = this.api.getDataManager({
      url: `${this.environment.api.prefix}/authority`,
      adaptor: this.paramsProfilAdaptor
    });
  }

  // tslint:disable-next-line: no-any
  actionFailure(e: any): void {
    if (e && e.error) {
      if (e.error.error && e.error.error instanceof XMLHttpRequest) {
        this.api.onError(e.error.error.statusText, e.error.error.status);
      }
    }
  }

  private failureCallback = () => {
    this.grid.instance.trigger('actionFailure');
    this.grid.instance.hideSpinner();
  }

  dataBound(): void {
    if (this.formDataReady) {
      this.treeGrid.hideSpinner();
    }

    this.treeGrid.collapseAll();
    const selectIndexes: number[] = [];

    this.treeGrid.getCurrentViewRecords().forEach((record: TreeParamProfil, index: number) => {
      if (record.checked) {
        selectIndexes.push(index);
      }
    });
    this.treeGrid.selectCheckboxes(selectIndexes);
  }

  beforeSave(): void {
    const paramsProfil = this.treeGrid.getCurrentViewRecords().concat(this.paramsProfilAdaptor.getHiddenData()) as (TreeParamProfil | ParamProfil)[];
    this.profilsAdaptor.setNewParamsProfil(paramsProfil);
  }

  getFormData(rowData: any): void {
    rowData = rowData as RawProfil;
    this.dmAuthorities = new DataManager([]);
    this.formData = {paramsProfil: []};
    this.formDataReady = false;

    this.dataProfils.executeQuery(new Query()).then(
      (e: ReturnOption) => {
        const response = e.result as RawProfil;

        // Initialisation de rowData dans le cas du create
        if (!rowData) {
          rowData = {name: ''};
        }

        // On détermine si une authority est active sur un profil si elle est fournie dans rowData (données de la ligne pour laquelle on a ouvert le dialog)
        // Si l'authority est présente on passe son flag checked à true pour cocher la case dans dataBound()
        response.authorities?.forEach((authority) => {
          if (rowData?.authorities) {
            authority.checked = rowData.authorities.some((auth: any) => authority.id === auth.id);
          }
        });

        rowData.authorities = response.authorities;
        this.formData = rowData as FormEditData;


        this.dmAuthorities = new DataManager(this.formData.authorities);
        this.formDataReady = true;
      }
    );
  }
}
