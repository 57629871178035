import {AfterViewInit, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {FormValidator, FormValidatorModel} from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'tlgpro-change-password-form',
  template: `
    <div id="form">
      <div class='form-group e-float-input'>
        <input type='password' name="password1" [(ngModel)]="password1" required />
        <span class='e-float-line'></span>
        <label class='e-float-text' for='password1' i18n="@@form.field.password">Mot de passe</label>
      </div>

      <div class='form-group e-float-input'>
        <input type='password' name="password2" [(ngModel)]="password2" required />
        <span class='e-float-line'></span>
        <label class='e-float-text' for='password2' i18n="@@form.field.password_confirm">Confirmation du mot de passe</label>
      </div>
    </div>

    <div *ngIf="password1 !== password2" class="e-error" i18n="@@form.error.password_confirm_not_match">Les mots de passe ne sont pas identiques.</div>
  `,
  styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit, AfterViewInit {

  private formValidator: FormValidator;

  password1?: string;
  password2?: string;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    const options: FormValidatorModel = {
      locale: this.locale,
      rules: {
        password1: { required: true },
        password2: { required: true }
      },
      customPlacement: (element: HTMLElement, error: HTMLElement) => {
        element.closest('.form-group')?.appendChild(error);
      }
    };

    this.formValidator = new FormValidator('#form', options);
  }

  public isValid(): boolean {
    return this.formValidator.validate() && this.password1 === this.password2;
  }

  public getValue(): string | undefined {
    return this.password1;
  }

  public reset(): void {
    delete this.password1;
    delete this.password2;
  }

}
