import {NgModule} from '@angular/core';
import {TlgproLayoutComponent} from './components';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {HeaderComponent} from './components/header/header.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SidebarNavComponent} from './components/sidebar/components/sidebar-nav/sidebar-nav.component';
import { HeaderBurgerIconComponent } from './components/header/components/header-burger-icon/header-burger-icon.component';
import {LocalStorageService} from '@tlgpro/util';

@NgModule({
  declarations: [
    TlgproLayoutComponent,
    SidebarComponent,
    HeaderComponent,
    SidebarNavComponent,
    HeaderBurgerIconComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TlgproLayoutComponent
  ],
  providers: [
    LocalStorageService
  ]
})
export class LayoutModule {
}
