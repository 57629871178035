import {ModuleWithProviders, NgModule, Provider} from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
  ],
  exports: []
})
export class ApiSyncfusionModule {
  public static forRoot(implementationProvider: Provider[]): ModuleWithProviders<ApiSyncfusionModule> {
    return {
      ngModule: ApiSyncfusionModule,
      providers: implementationProvider
    };
  }
}
