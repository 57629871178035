import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-logo-application',
  template: `
    <a [routerLink]="enableLink ? '/' : null"
       class="app-logo-link block font-semibold tracking-widest
         text-gray-900 dark:text-white uppercase text-[var(--app-name-font-size)]"
       [ngClass]="{'scale-150 !text-gray-900': loginDisplay}">
      <span class="app-logo ml-2 flex items-center h-full">{{appName}}</span>
    </a>
  `,
  styleUrls: ['./logo-application.component.css']
})
export class LogoApplicationComponent {
  @Input() public loginDisplay = false;
  @Input() public enableLink = true;
  public appName = environment.applicationName;

  constructor() {
  }

}
