import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  template: `
    <div>
      <h3 class="text-gray-500 dark:text-gray-400">
        <ng-container *ngFor="let part of breadcrumb; last as isLast">
          <span [ngClass]="{'font-semibold': isLast}">{{part}}</span>
          <span class="ml-2 mr-2" *ngIf="!isLast">/</span>
        </ng-container>
      </h3>
    </div>
  `,
  styleUrls: ['./breadcrumb.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent {
  public breadcrumb: string[];

  @Input() set title(title: string) {
    this.breadcrumb = title.split(' / ');
  }

  constructor() {
  }

}
