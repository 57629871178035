import { UrlAdaptor } from '@syncfusion/ej2-data';

export class ApiAdaptor extends UrlAdaptor {

  // tslint:disable-next-line: no-any
  public processResponse(resp: any): object {

    if (resp.data) {
      return resp.data;
    } else {
      return resp;
    }
  }
}
