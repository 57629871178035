import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {ChooseInTreePage} from './ui/choose-in-tree/choose-in-tree-page.component';
import {AuthInterceptor, AuthService, SecurityModule} from '@tlgpro/security';
import {environment} from '../environments/environment';
import {Store} from '@core/store';
import {GlobalErrorHandler, LogService} from '@core/log';
import {APIInterceptor, ApiService, ApiSyncfusionModule} from '@tlgpro/api-syncfusion';
import {AuthentificationModule} from './features/authentification/authentification.module';
import {I18nInterceptor} from '@core/i18n';
import {ToastModule} from '@syncfusion/ej2-angular-notifications';
import {LoadingModule} from './ui/browser-compatibility/browser-compatibility.module';
import {LocalStorageService} from '@tlgpro/util';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthentificationModule,
    ApiSyncfusionModule.forRoot([
      {
        provide: 'env',
        useValue: environment
      },
      {
        provide: 'store',
        useExisting: Store
      }
    ]),
    SecurityModule.forRoot([
      {
        provide: 'env',
        useValue: environment
      },
      {
        provide: 'store',
        useExisting: Store
      },
      {
        provide: 'log',
        useExisting: LogService
      }
    ]),
    ToastModule,
    LoadingModule
  ],
  declarations: [
    AppComponent,
    ChooseInTreePage
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    ApiService,
    LocalStorageService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: I18nInterceptor, multi: true},
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ]
})
export class AppModule {
}
