import {Component, Inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {INavData} from '../../models';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'tlgpro-layout',
  template: `
    <div #rootContainer class="h-screen w-screen overflow-hidden">
      <layout-header #header
                     [applicationNameTemplate]="applicationNameTemplate"
                     [headerTemplate]="headerTemplate"
                     [storageKey]="storageKey"
                     [forcedTheme]="forcedTheme"
                     [defaultTheme]="defaultTheme"
                     (toggleSidebarChange)="sidebar.toggleSidebar.next(null)"
                     (toggleMenuChange)="sidebar.toggleMenu.next(null)"
                     (darkThemeChange)="onThemeChange($event)"></layout-header>
      <div class="w-full flex h-[calc(100%-var(--header-height))] flex-col md:flex-row relative dark:bg-gray-700 bg-gray-50">
        <layout-sidebar #sidebar [navs]="nav"
                        (sidebarStateChange)="header.sidebarState = $event"
                        (menuStateChange)="header.menuState = $event"></layout-sidebar>
        <main class="w-full h-full overflow-auto md:border-l bg-white dark:text-gray-200 dark:bg-gray-800 dark:border-l-gray-600">
          <ng-container *ngTemplateOutlet="routerOutletTemplate ? routerOutletTemplate : null">
          </ng-container>
        </main>
      </div>
    </div>
  `,
  styleUrls: ['./tlgpro-layout.component.css']
})
export class TlgproLayoutComponent implements OnInit {
  @ViewChild('rootContainer') public rootContainer?:  HTMLElement;

  @Input() public routerOutletTemplate?: TemplateRef<any>;
  @Input() public nav?: INavData[];

  @Input() public storageKey?: string;

  /** Custom template pour le header */
  @Input() public headerTemplate?: TemplateRef<any>;

  @Input() public applicationNameTemplate?: TemplateRef<any>;

  @Input() public defaultTheme?: 'light' | 'dark';
  @Input() public forcedTheme?: 'light' | 'dark';

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
  }

  public onThemeChange(isDark: boolean): void {
    if (isDark) {
      this.document.body.classList.add('dark');
      this.document.body.classList.remove('light');
    } else {
      this.document.body.classList.add('light');
      this.document.body.classList.remove('dark');
    }
  }

}
