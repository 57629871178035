import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ToastSyncfusionService} from '@tlgpro/toast-syncfusion';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {EnvironmentData} from '../models/tools/environment-data';
import {LogServiceInterface} from '../models/log-service.interface';


@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(
    private http: HttpClient,
    private toast: ToastSyncfusionService,
    @Inject('log') private log: LogServiceInterface,
    @Inject('env') private environment: EnvironmentData
  ) {
  }

  /**
   * Update Password
   */
  updatePassword(data: { idUser: number, password: string | undefined }): Observable<unknown> {
    if (!data.password) {
      const errMsg = $localize`Le nouveau mot de passe ne peut pas être vide`;
      this.log.getLogger('administration').error(errMsg);
      this.toast.show({
        content: $localize`Erreur lors de la modification du mot de passe`,
      });
      return throwError(errMsg);
    }

    return this.http.post<unknown>(`${this.environment.api.prefix}/user/update-password`, data).pipe(
      catchError((error) => {
        this.log.getLogger('administration').error(error);
        this.toast.show({
          content: $localize`Erreur lors de la mise à jour de la base de données`,
        });
        return throwError(error);
      })
    );
  }
}
