import {Component, OnInit, ViewChild} from '@angular/core';
import {I18nService} from '@core/i18n';
import {ToastComponent, ToastModel} from '@syncfusion/ej2-angular-notifications';
import {Subscription} from 'rxjs';
import {ToastSyncfusionService} from '@tlgpro/toast-syncfusion';
import { Title } from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
  // tslint:disable-next-line
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body',
  template: `
    <app-browser-compatibility></app-browser-compatibility>
    <router-outlet></router-outlet>

    <ejs-toast #toast
               [showProgressBar]="true"
               [showCloseButton]="true"
               [timeOut]="15000"
               [extendedTimeout]="15000"
               [position]="{X:'Right', Y:'Bottom'}">
    </ejs-toast>
  `,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  private defaultToast: ToastModel = {
    title: $localize`Erreur`,
    cssClass: 'e-toast-danger'
  };

  @ViewChild('toast')
  private toastComponent: ToastComponent;

  private toastEventSubscription: Subscription;

  constructor(
    private i18nService: I18nService,
    private toast: ToastSyncfusionService,
    private titleService: Title) {
  }

  ngOnInit(): void {
    // Update Title
    this.titleService.setTitle(`${environment.applicationName}`);

    this.i18nService.localizeSyncfusion();

    // Listen toast event to show toast element
    this.toastEventSubscription = this.toast.state.subscribe((msg) => {
      this.toastComponent.show(Object.assign({}, this.defaultToast, msg));
    });
  }
}
