import { Injectable } from '@angular/core';
import { Store } from '@core/store';
import { RxStomp, RxStompState } from '@stomp/rx-stomp';
import {Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {IMessage} from '@stomp/stompjs';

@Injectable({
  providedIn: 'root'
})
export class RxStompService {
  private rxStomp: RxStomp;
  private monitorSubject = new Subject<RxStompState>();
  // Etat de la connexion, à utiliser pour afficher l'état à l'utlisateur
  public monitor$ = this.monitorSubject.asObservable();

  private wsConnectionStateSubscrioption: Subscription;

  constructor(private store: Store) {}

  public connect(): void {
    this.rxStomp = new RxStomp();

    this.rxStomp.configure({
      brokerURL: `${environment.ws.protocol}://${window.location.host}${environment.ws.prefix}`,
      reconnectDelay: 4_000,
      connectionTimeout: 2_000,
      beforeConnect: (client: RxStomp) => {
        // On récupère le token actuel avant chaque tentative de connexion
        client.stompClient.connectHeaders.token = `${this.store.selectSnapshot('accessToken')}`;
      }
    });

    this.wsConnectionStateSubscrioption = this.rxStomp.connectionState$.subscribe((state: RxStompState) => {
      this.monitorSubject.next(state);
    });

    this.rxStomp.activate();
  }

  public close(): void {
    if (this.rxStomp) {
      this.rxStomp.deactivate();
    }
  }

  public watch(topic: string): Observable<IMessage> {
    return this.rxStomp.watch(topic).pipe(
      map((message: { body: string }) => {
        return JSON.parse(message.body);
      })
    );
  }

  public send(destination: string, payload: object): void {
    this.rxStomp.publish({destination, body: JSON.stringify(payload)});
  }
}
