import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';

@Injectable({
  providedIn: 'root'
})
export class ToastSyncfusionService {

  private store = new Subject<ToastModel>();
  public state = this.store.asObservable();

  constructor() { }

  show(msg: ToastModel): void {
    this.store.next(msg);
  }
}
