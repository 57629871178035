import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AdaptorOptions, DataOptions, Query} from '@syncfusion/ej2-data';
import {ToastSyncfusionService} from '@tlgpro/toast-syncfusion';
import {ApiDataManager} from './api-data-manager';
import {StoreInterface} from './models/store.interface';
import {ApiServiceInterface} from './apiService.interface';
import {EnvironmentData} from './models/environment-data';


@Injectable({
  providedIn: 'root'
})
export class ApiService implements ApiServiceInterface {

  constructor(
    private toast: ToastSyncfusionService,
    private router: Router,
    @Inject('env') private environment: EnvironmentData,
    @Inject('store') private store: StoreInterface,
  ) { }

  onError(message: string, code?: number, sessionExpired?: boolean): void {

    let msg = `${message}`;

    if (code) {

      msg = `${code} : ${message}`;

      // Session Expired or Unauthorized
      if (sessionExpired === true || code === 403) {
        this.router.navigate([this.environment?.router.logout]);
        return;
      }
      // Authentication error
      else if (code === 401) {
        // msg = $localize`:@@login.invalid_credentials:Informations de connexion erronées`;
        msg = `Informations de connexion erronées`;
      }

    }

    this.toast.show({
      content: msg
    });

    throw new Error(msg);
  }

  onXMLHttpRequestError(request: XMLHttpRequest): void {

    let message: string = request.statusText;
    let code: number = request.status;
    const sessionExpired: boolean = request.getResponseHeader('session_expired') !== null;

    try {
      const responseText = request.responseText;
      const responseJson = JSON.parse(responseText);

      if (responseJson.statuts) {
        code = responseJson.statuts;
      }

      if (responseJson.error) {
        message = responseJson.error;
      }

      if (responseJson.message) {
        message = `${message} - ${responseJson.message}`;
      }
    } catch (e) {
    }

    this.onError(message, code, sessionExpired);
  }

  getDataManager(dataSource?: DataOptions, query?: Query, adaptor?: AdaptorOptions | string, errorCallback?: () => void): ApiDataManager {

    const dataManager = new ApiDataManager(this, dataSource, query, adaptor);

    const beforeSendFn = (dataManager as any).beforeSend;
    const apiService = this;
    (dataManager as any).beforeSend = function(request: XMLHttpRequest, settings: any) {

      // Set JWT Token Header
      request.setRequestHeader('Authorization', `Bearer ${apiService.store?.selectSnapshot('accessToken')}`);

      // Add error handler
      const fail = settings.failureHandler;
      settings.failureHandler = function(data: any) {

        if (errorCallback) {
          errorCallback();
        }

        apiService.onXMLHttpRequestError((this.httpRequest as XMLHttpRequest));
        fail.call(this, data);
      };

      beforeSendFn.call(this, request, settings);
    };

    return dataManager;
  }
}
