import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private log: LogService) { }

  // tslint:disable-next-line: no-any
  handleError(error: any): void {

    // Route error to the default logger
    this.log.getDefaultLogger().error(error);
  }
}
