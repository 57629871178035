import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfigChangeService} from '../../services/config-change.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-config-ack-button',
  template: `
    <div *ngIf="countChanges && countChanges > 0" class="badge-block">
      <button ejs-button class="!text-white !bg-orange-500" (click)="btnClick.emit()" 
              i18n-data-content="@@config.ack.button" data-content="acquitter la configuration">
        <i class="fas fa-sync-alt fa-spin"></i>
      </button>
      <span class="e-badge e-badge-info e-badge-notification e-badge-overlap">{{countChanges | number}}</span>
    </div>
  `,
  styleUrls: ['./config-ack-button.component.css']
})
export class ConfigAckButtonComponent implements OnInit, OnDestroy {
  @Output() btnClick = new EventEmitter();

  public countChanges?: number;
  private countConfigChangesSubscription: Subscription;

  constructor(private configChangeService: ConfigChangeService) {
  }

  ngOnInit(): void {
    this.countConfigChangesSubscription = this.configChangeService.watchCountChanges().subscribe(countChanges => this.countChanges = countChanges);
  }

  ngOnDestroy(): void {
    this.countConfigChangesSubscription?.unsubscribe();
  }

}
