import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {filter, Subscription} from 'rxjs';
import {INavData} from '../../models';
import {Event, NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'layout-sidebar',
  template: `
    <aside class="z-50 w-full absolute md:static transition-[height] md:transition-[width] duration-300 md:[&>*]:w-[var(--sidebar-width)] text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-700"
           [ngClass]="{'h-full': openNav, 'h-0 md:h-full': !openNav, 'md:w-[var(--sidebar-width)]': showSidebar, 'md:w-0': !showSidebar}">
      <nav class="md:block md:pb-0 h-full overflow-auto">
        <app-sidebar-nav
                class="md:h-[calc(100%-var(--sidebar-tlgpro-btn-height))] flex flex-col overflow-hidden md:overflow-y-auto"
                [navs]="navs"></app-sidebar-nav>
        <a href="https://www.tlgpro.fr/" target="_blank"
           class="hidden md:block text-sm decoration-gray-50
                  h-[var(--sidebar-tlgpro-btn-height)]
                  hover:bg-blue-500 hover:underline 
                  [&>*]:text-gray-400 hover:[&>*]:text-gray-50
                  dark:hover:bg-blue-400 dark:hover:text-gray-50
                  focus:outline-none focus:shadow-outline">
          <span class="logo-tlgpro ml-2 flex items-center h-full">Powered by TLG Pro</span>
        </a>
      </nav>
    </aside>
  `,
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Output() public sidebarStateChange = new EventEmitter<boolean>();
  @Output() public menuStateChange = new EventEmitter<boolean>();
  @Input() public navs?: INavData[];

  public openNav = false;
  public showSidebar = true;
  public toggleSidebar = new EventEmitter<null>();
  public toggleMenu = new EventEmitter<null>();

  private routerSubscription?: Subscription;
  private toggleSidebarSubscription?: Subscription;
  private toggleMenuSubscription?: Subscription;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd)
    ).subscribe(() => {
      this.openNav = false;
      this.menuStateChange.next(this.openNav);
    });

    this.toggleSidebarSubscription = this.toggleSidebar.subscribe(() => {
      this.showSidebar = !this.showSidebar;
      this.sidebarStateChange.next(this.showSidebar);
    });

    this.toggleMenuSubscription = this.toggleMenu.subscribe(() => {
      this.openNav = !this.openNav;
      this.menuStateChange.next(this.openNav);
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.toggleSidebarSubscription?.unsubscribe();
    this.toggleMenuSubscription?.unsubscribe();
  }

}

