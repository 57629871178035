import { ApiAdaptor } from './api-adaptor';

export class SimpleListAdaptor extends ApiAdaptor {
  // tslint:disable-next-line: no-any
  public processResponse(resp: any): object {
    // tslint:disable-next-line: no-any
    let data: any = super.processResponse(resp);

    // Récupération des résultats
    if (data.content) {
      // Version spring
      data = data.content;
    } else if (data.result) {
      // Version rétro compatible, jackson api (avant spring)
      data = data.result;
    } else {
      console.warn(`Aucun contenu trouvé`);
    }

    return data;
  }
}
