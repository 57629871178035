import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-loading-page',
  template: `
    <div id="container" [style.transform]="'scale('+ scale +')'">
      <div>
        <ng-content></ng-content>
      </div>

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 52 12" enable-background="new 0 0 0 0" xml:space="preserve">
        <circle fill="#fff" stroke="none" cx="6" cy="6" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/>
        </circle>
        <circle fill="#fff" stroke="none" cx="26" cy="6" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/>
        </circle>
        <circle fill="#fff" stroke="none" cx="46" cy="6" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/>
        </circle>
      </svg>
    </div>
  `,
  styleUrls: ['./loading-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingPageComponent {
  @Input() public scale = 1;

  constructor() { }

}
