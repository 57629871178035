import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ProfilsPage} from './admin/pages/profils/profils.page';
import {SessionsPage} from './admin/pages/sessions/sessions.page';
import {UtilisateursPage} from './admin/pages/utilisateurs/utilisateurs.page';
import {GridSyncfusionModule} from '@tlgpro/grid-syncfusion';
import {ApiSyncfusionModule} from '@tlgpro/api-syncfusion';
import {TreeGridAllModule} from '@syncfusion/ej2-angular-treegrid';
import {DialogAllModule} from '@syncfusion/ej2-angular-popups';
import {ChangePasswordFormComponent} from './admin/components/change-password-form/change-password-form.component';
import {CommonModule} from '@angular/common';
import {LocalStorageService} from '@tlgpro/util';

@NgModule({
  declarations: [
    ProfilsPage,
    SessionsPage,
    UtilisateursPage,
    ChangePasswordFormComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    GridSyncfusionModule,
    ApiSyncfusionModule,
    TreeGridAllModule,
    DialogAllModule,
    CommonModule
  ],
  exports: [
  ],
  providers: [LocalStorageService]
})
export class SecurityModule {
  public static forRoot(implementationProvider: Provider[]): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: implementationProvider
    };
  }
}
