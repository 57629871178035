import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, LOCALE_ID, OnDestroy} from '@angular/core';
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import enCaGregorian from 'cldr-data/main/en/ca-gregorian.json';
import enNumbers from 'cldr-data/main/en/numbers.json';
import enTimeZoneNames from 'cldr-data/main/en/timeZoneNames.json';
import frCaGregorian from 'cldr-data/main/fr/ca-gregorian.json';
import frNumbers from 'cldr-data/main/fr/numbers.json';
import frTimeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import weekdata from 'cldr-data/supplemental/weekData.json';

import {LogService} from '@core/log';

import moment from 'moment';
import {Subscription} from 'rxjs';

// Common
// fr
// en
@Injectable({
  providedIn: 'root'
})
export class I18nService implements OnDestroy {

  readonly syncfusionFilesPath = 'locale/ej2-locale';

  readonly syncfusionFiles = ['ar-AE', 'ar', 'cs', 'da', 'de', 'en-GB', 'en-US', 'es', 'fa', 'fi', 'fr', 'he', 'hr', 'hu', 'id', 'it', 'ja', 'ko', 'ms', 'nb', 'nl', 'pl', 'pt', 'ro', 'ru', 'sk', 'sv', 'th', 'tr', 'vi', 'zh'];
  private i18nConfigSubscription: Subscription;

  constructor(
    private log: LogService,
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string) {

    this.log.getLogger('i18n').debug(`Current locale : ${this.locale}`);

    loadCldr(
      // Common
      weekdata,
      numberingSystems,

      // fr
      frNumbers,
      frCaGregorian,
      frTimeZoneNames,

      // en
      enNumbers,
      enCaGregorian,
      enTimeZoneNames
    );
  }

  localizeMoment(locale?: string): void {
    let localeName = locale;
    if (!localeName) {
      localeName = this.locale;
    }

    moment.locale(localeName);
  }

  localizeSyncfusion(locale?: string): void {

    let localeName = locale;
    if (!localeName) {
      localeName = this.locale;
    }

    let file: string | undefined;
    if (this.syncfusionFiles.includes(localeName)) {
      file = localeName;
    } else if (this.syncfusionFiles.includes(localeName.split('-')[0])) {
      file = localeName.split('-')[0];
    } else if (this.syncfusionFiles.includes(localeName.split('_')[0])) {
      file = localeName.split('_')[0];
    }

    if (file) {
      setCulture(file);

      // load local files
      this.i18nConfigSubscription = this.http.get(`${this.syncfusionFilesPath}/${file}.json`).subscribe((response) => {

        // TODO : Quick fix
        if (file === 'fr') {
          // tslint:disable-next-line: no-any
          (response as any).fr.uploader.Browse = 'Parcourir';
          (response as any).fr.grid.ClearButton = 'Effacer';
          (response as any).fr.grid.FilterButton = 'Filtrer';
        }

        L10n.load(response);
      });
    }
  }

  ngOnDestroy(): void {
    this.i18nConfigSubscription?.unsubscribe();
  }
}
