import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiService} from './api.service';
import {EnvironmentData} from './models/environment-data';
import {StoreInterface} from './models/store.interface';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(private api: ApiService,
              @Inject('env') private environment: EnvironmentData,
              @Inject('store') private store: StoreInterface) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (req.url.indexOf(this.environment.api.prefix) !== -1) {

      // Check if there is a token
      const authReq = !this.store.selectSnapshot('accessToken') ? req : req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.store.selectSnapshot('accessToken')}`)
      });

      return next.handle(authReq).pipe(
        tap(() => {
          }, (error) => {

            if ((error instanceof HttpErrorResponse)) {
              this.api.onError(error.error?.message || error.message, error.status, error.headers.has('session_expired'));
            }

          }
        )
      );

    } else {
      return next.handle(req);
    }

  }

}
