import {INavData} from '@tlgpro/layout/lib/models';
import {SecureItem, SpecificSecureFilter} from '../secure-filter';

export class INavDataFilter implements SpecificSecureFilter<INavData> {
    collect(item: SecureItem<INavData>): INavData {
        return {...item, children: item.secureChildren};
    }

    transform(children: INavData[]): SecureItem<INavData>[] {
        return children.map((item: INavData) => ({
            ...item,
            secureChildren: item.children && this.transform(item.children)
        }));
    }
}
