import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  ActionEventArgs,
  AddEventArgs,
  ClipMode,
  Column,
  ColumnModel,
  CommandClickEventArgs,
  DeleteEventArgs,
  DialogEditEventArgs,
  EditEventArgs,
  EditSettingsModel,
  ExcelExportProperties,
  FailureEventArgs,
  FilterEventArgs,
  FilterSettingsModel,
  GridComponent as ej2GridComponent,
  GroupEventArgs,
  HeaderCellInfoEventArgs,
  PageEventArgs,
  PageSettingsModel,
  SaveEventArgs,
  SearchEventArgs,
  SelectionSettingsModel,
  SortEventArgs,
  SortSettingsModel,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { Dialog, DialogModel, Tooltip } from '@syncfusion/ej2-angular-popups';
import { Browser } from '@syncfusion/ej2-base';
import { DataManager } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { SimpleListAdaptor } from '@tlgpro/api-syncfusion';

import { RendererComponent } from './renderer.component';

@Component({
  selector: 'tlgpro-grid-syncfusion',
  template: `
    <tlgpro-renderer #renderer></tlgpro-renderer>

    <ejs-grid #grid
      [height]='height'
      [dataSource]='dataSource'
      [columns]='columns'
      [allowPaging]='allowPaging'
      [allowFiltering]='allowFiltering'
      [allowSorting]='allowSorting'
      [allowGrouping]='allowGrouping'
      [allowReordering]='allowReordering'
      [allowSelection]="allowSelection"
      [allowResizing]="allowResizing"
      [allowExcelExport]='allowExcelExport'
      [selectionSettings]="selectionSettings"
      [filterSettings]='filterSettings'
      [pageSettings]='pageSettings'
      [editSettings]='editSettings'
      [sortSettings]='sortSettings'
      [toolbar]='toolbar'
      [showColumnChooser]='showColumnChooser'
      [showColumnMenu]='showColumnMenu'
      [columnMenuItems]='columnMenuItems'
      [clipMode]='clipMode'
      [detailTemplate]='detailTemplate'
      (actionBegin)='actionBegin($event)'
      (actionComplete)='actionComplete($event)'
      (actionFailure)="onActionFailure($event)"
      (dataBound)="dataBound()"
      (toolbarClick)='toolbarClick($event)'
      (headerCellInfo)='headerCellInfo($event)'
      (commandClick)='onCommandClick($event)'>
  </ejs-grid>
  `,
  styleUrls: ['./grid-syncfusion.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GridSyncfusionComponent implements OnInit, AfterViewInit {
  @Output() actionFailure = new EventEmitter<FailureEventArgs>();
  @Output() beginEdit = new EventEmitter<object>();
  @Output() beginAdd = new EventEmitter<object>();
  @Output() save = new EventEmitter<DialogEditEventArgs>();
  @Output() cancel = new EventEmitter<DialogEditEventArgs>();
  @Output() paging = new EventEmitter<PageEventArgs>();
  @Output() openDialog = new EventEmitter<object>();
  @Output() closeDialog = new EventEmitter<object>();
  @Output() beforeSave = new EventEmitter<SaveEventArgs>();
  @Output() commandClick = new EventEmitter<CommandClickEventArgs>();
  /**
   * Propage l'évènement AfterViewInit du composant à l'extérieur
   */
  @Output() afterViewInit = new EventEmitter<null>();

  @Input() primaryKey: string;
  @Input() height: number | string = '100%';
  @Input() dataSource: DataManager | object[];
  @Input() columns: ColumnModel[];

  @Input() dialogWidth = 500;
  @Input() pageSettings: PageSettingsModel = {};
  private defaultPageSettings: PageSettingsModel = {
    pageSizes: [50, 100, 250, 500],
    pageSize: 50
  };
  @Input() selectionSettings: SelectionSettingsModel = {
    type: 'Multiple'
  };
  @Input() filterSettings: FilterSettingsModel = {
    type: 'Menu'
  };
  @Input() allowFiltering = true;
  @Input() allowPaging = true;
  @Input() allowSorting = true;
  @Input() allowGrouping = false;
  @Input() allowReordering = true;
  @Input() allowSelection = true;
  @Input() allowResizing = true;
  @Input() allowExcelExport = true;
  @Input() showColumnMenu = true;
  @Input() showColumnChooser = true;
  @Input() customValidation = true;
  @Input() excelExportProperties: ExcelExportProperties;
  @Input() editSettingsTemplate: TemplateRef<unknown>;
  @Input() columnMenuItems: string[] = [
    // 'AutoFitAll',     // - Auto fit the size of all columns.
    // 'AutoFit',        // - Auto fit the current column.
    // 'Group',          // - Group by current column.
    // 'Ungroup',        // - Ungroup by current column.
    'SortAscending',  // - Sort the current column in ascending order.
    'SortDescending', // - Sort the current column in descending order.
    'ColumnChooser',   // - Choose the column visibility'
    'Filter'         // - Filter options will show based on filterSettings property like checkbox filter, excel filter, menu filter.
  ];

  @Input() clipMode: ClipMode;

  @Input() settings: EditSettingsModel;
  editSettings: EditSettingsModel;

  @Input() sortSettings: SortSettingsModel;

  @Input() showToolbar = true;
  toolbar: ToolbarItems[] | null = ['Add', 'Edit', 'Delete', 'Search', 'ExcelExport'];

  @Input() doSave: (args: SaveEventArgs) => void;

  // tslint:disable-next-line: no-any
  @Input() detailTemplate: any;

  @ViewChild('renderer') public renderer: RendererComponent;
  @ViewChild('grid') instance: ej2GridComponent;

  dialog: DialogModel | null | undefined = null;

  private initAdaptor: any;

  constructor() { }

  ngAfterViewInit(): void {
    this.afterViewInit.next(null);

    this.renderer.render(this.columns, this.instance);

    if (this.dataSource && (this.dataSource as DataManager).adaptor) {
      this.initAdaptor = (this.dataSource as DataManager).adaptor;
    }
  }

  addRenderers(customRenderers: Map<string, NgModel>): void {
    this.renderer.setCustomRenderers(customRenderers);
    this.renderer.render(this.columns, this.instance);
  }

  ngOnInit(): void {
    this.applySettings();

    // Diasable actions on save
    this.beforeSave.subscribe(
      () => this.enableActionsOnSave(false)
    );

    // Hide toolbar
    if (!this.showToolbar) {
      this.toolbar = null;
    }

    // Remove column chooser from toolbar and column menu if isn't enabled
    if (this.toolbar && !this.showColumnChooser) {
      let index = this.toolbar.indexOf('ColumnChooser');
      if (index > -1) {
        this.toolbar.splice(index, 1);
      }

      index = this.columnMenuItems.indexOf('ColumnChooser');
      if (index > -1) {
        this.columnMenuItems.splice(index, 1);
      }
    }

  }

  applySettings(): void {
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      mode: 'Dialog',
      template: this.editSettingsTemplate
    };

    Object.assign(this.editSettings, this.settings);

    this.pageSettings = Object.assign({}, this.defaultPageSettings, this.pageSettings);
  }

  actionBegin(args: PageEventArgs | GroupEventArgs | FilterEventArgs | SearchEventArgs | SortEventArgs | AddEventArgs | SaveEventArgs | EditEventArgs | DeleteEventArgs | ActionEventArgs): void {
    switch (args.requestType?.toLowerCase()) {
      case 'save': {
        const event = args as SaveEventArgs;
        if (this.doSave) {
          this.doSave(event);
        }

        if (!event.cancel) {
          this.beforeSave.emit(event);

          if (event.promise) {
            event.promise?.catch(() => {
              this.enableActionsOnSave(true);
            });
          }
        }
        break;
      }
      case 'filtering':
      case 'sorting':
      case 'paging':
      case 'refresh': {
        if (this.initAdaptor) {
          // Gestion des filtres dans les entêtes de colonne
          this.instance.getDataModule().dataManager.adaptor = this.initAdaptor;
        }
        break;
      }
      case 'filtersearchbegin': {
        // Gestion des filtres dans les entêtes de colonne
        if (this.instance.filterSettings.type === 'Excel') {
          this.instance.getDataModule().dataManager.adaptor = new SimpleListAdaptor();
        }
        break;
      }
      case 'filterbeforeopen': {
        // Gestion des filtres dans les entêtes de colonne
        if (this.instance.filterSettings.type === 'Menu') {
          this.instance.getDataModule().dataManager.adaptor = new SimpleListAdaptor();
        }
        break;
      }
    }
  }

  public enableActionsOnSave(enabled = true): void {
    if (this.dialog && this.dialog.buttons) {
      // TODO check new logic
      const dialogButtons = this.dialog.buttons;
      if (dialogButtons[0].buttonModel && dialogButtons[1].buttonModel) {
        dialogButtons[0].buttonModel.disabled = !enabled;
        dialogButtons[0].buttonModel.cssClass = enabled ? '' : 'saving';
        dialogButtons[1].buttonModel.disabled = !enabled;
        this.dialog.buttons = dialogButtons;
      }

      this.dialog.showCloseIcon = enabled;
      this.dialog.closeOnEscape = enabled;

      if (enabled) {
        this.instance.hideSpinner();
      }
    }
  }

  onActionFailure(e: FailureEventArgs): void {
    this.enableActionsOnSave(true);
    this.actionFailure.emit(e);
  }

  actionComplete(args: any): void {
    this.dialog = args.dialog;

    switch (args.requestType) {
      case 'add':
        this.onAdd(args);
        break;
      case 'beginEdit':
        this.onBeginEdit(args);
        break;
      case 'save':
        this.onSave(args);
        break;
      case 'cancel':
        this.onCancel(args);
        break;
      case 'paging':
        this.onPaging(args);
        break;
    }
  }

  private onAdd(args: DialogEditEventArgs): void {
    this.openForm(args);
    this.openDialog.emit();
    this.beginAdd.emit(args.rowData);
  }

  private onBeginEdit(args: DialogEditEventArgs): void {
    this.openForm(args);
    this.openDialog.emit();
    this.beginEdit.emit(args.rowData);
  }

  private onSave(args: DialogEditEventArgs): void {
    this.save.emit(args);
    this.closeDialog.emit();
    this.instance.refresh();
  }

  private onCancel(args: DialogEditEventArgs): void {
    this.cancel.emit(args);
    this.closeDialog.emit();
  }

  private onPaging(args: PageEventArgs): void {
    this.paging.emit(args);

    // Scroll to top on paging
    this.scrollToTop();
  }

  onCommandClick(e: CommandClickEventArgs): void {
    this.commandClick.emit(e);
  }

  openForm(args: DialogEditEventArgs): void {
    if ((args.requestType === 'beginEdit' || args.requestType === 'add') && args.form) {
      if (args.dialog) {
        if (Browser.isDevice) {
          (args.dialog as Dialog).dataBind();
        }
        // change the header of the dialog
        if (args.rowData) {
          args.dialog.header = args.requestType === 'beginEdit' ? 'Modification' : 'Ajouter un enregistrement';
          args.dialog.width = this.dialogWidth;

          if (this.customValidation) {
            this.instance.editModule.formObj.customPlacement = (inputElement: HTMLElement, errorElement: HTMLElement) => {
              if (inputElement.parentElement && inputElement.parentElement.parentElement) {
                inputElement.parentElement.parentElement.appendChild(errorElement);
              }
            };
          }
        }
      }

      // Set initial Focus
      if (args.form && args.form.elements && args.form.elements.item(0) &&
        typeof (args.form.elements.item(0) as HTMLInputElement).focus !== 'undefined') {
        (args.form.elements.item(0) as HTMLInputElement).focus();
      }
    }
  }

  dataBound(): void {
    const primary = this.getPrimaryKey();
    if (primary) {
      const column: ColumnModel = this.instance.getColumnByField(primary);
      if (column) {
        column.isPrimaryKey = true;
      }
    }
  }

  toolbarClick(args: ClickEventArgs): void {
    if ((args.item.id as string).endsWith('_excelexport')) { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
      this.instance.excelExport(this.excelExportProperties);
    }
  }

  /**
   * Gestion des tooltip sur les entêtes de tableau
   */
  headerCellInfo(args: HeaderCellInfoEventArgs): void {
    if (args && args.cell && args.node) {
      // let content = args.cell.column.headerText;
      if (args.cell.column.customAttributes && args.cell.column.customAttributes.tooltip) {
        const content = args.cell.column.customAttributes.tooltip as string;

        const tooltip: Tooltip = new Tooltip({
          content
        });

        tooltip.appendTo(args.node as HTMLElement);
      }
    }
  }

  /**
   * Détermination de la primary key
   */
  private getPrimaryKey(): string | undefined {
    let primary: string | undefined;

    if (this.dataSource instanceof DataManager && this.dataSource.dataSource.key) {
      primary = this.dataSource.dataSource.key;
    } else if (this.primaryKey) {
      primary = this.primaryKey;
    }

    return primary;
  }

  getPrimaryColumn(): Column | null {
    const primaryKey = this.getPrimaryKey();
    let column = null;
    if (primaryKey) {
      column = this.getColumnByUid(primaryKey);

      if (column === null) {
        column = this.getColumnByField(primaryKey);
      }
    }

    return column;
  }

  getColumnByUid(uid: string): Column {
    return this.instance.getColumnByUid(uid);
  }

  getColumnByField(field: string): Column {
    return this.instance.getColumnByField(field);
  }

  scrollToTop(): void {
    const table = this.instance.getContent().querySelector('.e-table');
    if (table && table.parentElement) {
      table.parentElement.scrollTop = 0;
    }
  }
}
