import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ApplicationComponent} from './pages/application/application.page';
import {AuthoritiesGuard} from '@tlgpro/security';

const routes: Routes = [
  {
    path: '', component: ApplicationComponent, canActivate: [AuthoritiesGuard], data: {title: 'Accueil'}, children: [
      {
        path: 'services',
        loadChildren: () => import('../node-service/node-service.module').then(m => m.NodeServiceModule),
        data: {authorities: ['settings.service']}
      },
      {
        path: 'statuts',
        loadChildren: () => import('../statut-service/status-service.module').then(m => m.StatutServiceModule),
        data: {authorities: ['settings.status']}
      },
      {
        path: 'realtime',
        loadChildren: () => import('../realtime/realtime-metric.module').then(m => m.RealtimeMetricModule),
        data: {authorities: ['realtime']}
      },
      {
        path: 'metrics',
        loadChildren: () => import('../metrics/metrics.module').then(m => m.MetricsModule),
        data: {authorities: ['settings.metric']}
      },
      {
        path: 'alarms',
        loadChildren: () => import('../alarms/alarms.module').then(m => m.AlarmsModule),
        data: {authorities: ['settings.alarm']}
      },
      {
        path: 'administration',
        loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'templates',
        loadChildren: () => import('../templates/templates.module').then(m => m.TemplatesModule),
        data: {authorities: ['templates']}
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicationRoutingModule {
}
