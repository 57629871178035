import {Injectable} from '@angular/core';
import log from 'loglevel';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {

    const originalFactory = log.methodFactory;
    log.methodFactory = (methodName, logLevel, loggerName) => {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);

      return (...message) => {
        rawMethod(`[${String(loggerName ? loggerName : 'root')}]`, ...message);
      };
    };

    // Set log level
    log.setLevel(environment.log.level.root);
    this.getDefaultLogger().debug(`Log level : ${environment.log.level.root}`);

    // Set loggers level
    for (const loggerName in environment.log.level) {
      if (loggerName === 'root') {
        continue;
      }

      this.getLogger(loggerName).setLevel(environment.log.level[loggerName]);
      this.getLogger(loggerName).debug(`Log level : ${environment.log.level[loggerName]}`);
    }
  }

  public static getLogger(name: string): log.Logger {
    return log.getLogger(name);
  }

  public static getDefaultLogger(name: string): log.Logger {
    return log.getLogger(name);
  }

  public getDefaultLogger(): log.Logger {
    return log;
  }

  public getLogger(name: string): log.Logger {
    return log.getLogger(name);
  }
}
