import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AckStatus, Action, LogConfig} from '../../models/log-config';
import {ConfigChangeService} from '../../services/config-change.service';
import {Subscription} from 'rxjs';
import {ProgressButtonComponent} from '@syncfusion/ej2-angular-splitbuttons';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import {ToolsService} from '@core/tools';

@Component({
  selector: 'app-config-ack-dialog',
  template: `
    <ejs-dialog #dialog width='1100px' [isModal]="true"
                [showCloseIcon]="true"
                [visible]="false">
      <ng-template #header>
        <h2><i class="fas fa-sync-alt"></i>
          <ng-container i18n="@@config.ack.dialog.loading.msg">Changements de configuration en attente d'acquittement</ng-container>
        </h2>
      </ng-template>
      <ng-template #content>
        <div class="content">
          <div class="position-relative">
            <div *ngIf="!logConfigs || logConfigs.length === 0"
                 class="loading-screen w-100 h-100 bg-dark position-absolute">
              <app-loading-page></app-loading-page>
            </div>
            <ejs-grid #grid class="d-flex flex-column" [dataSource]="logConfigs" [allowSorting]="true"
                      [height]="500"
                      [pageSettings]="{ pageSizes: true, pageSize: 12 }">
              <e-columns>
                <e-column field="eventDate" headerText="Date" width="180" type="date"
                          i18n-headerText="@@config.ack.dialog.column.date"
                          [format]="{type: 'dateTime', skeleton: 'short'}">
                </e-column>
                <e-column field="entity" headerText="Entité" width="170"
                          i18n-headerText="@@config.ack.dialog.column.entity"></e-column>
                <e-column field="entityId" headerText="ID Entité"
                          i18n-headerText="@@config.ack.dialog.column.entityId"></e-column>
                <e-column field="eventUsername" headerText="Utilisateur"
                          i18n-headerText="@@config.ack.dialog.column.user"></e-column>
                <e-column field="action" headerText="Action" [template]="actionRenderer" width="180"
                          i18n-headerText="@@config.ack.dialog.column.action">
                  <ng-template #actionRenderer let-data>
                    <ng-container [ngSwitch]="data['action']">
                      <ng-container *ngSwitchCase="action[action.CREATE]">
                        <i class="fas fa-plus-circle"></i> Création
                      </ng-container>
                      <ng-container *ngSwitchCase="action[action.EDIT]">
                        <i class="fas fa-pen"></i> Modification
                      </ng-container>
                      <ng-container *ngSwitchCase="action[action.DELETE]">
                        <i class="fas fa-trash-alt"></i> Suppression
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </e-column>
                <e-column field="ackStatus" headerText="Status d'acquittement" [template]="acqStatusRenderer"
                          width="180" i18n-headerText="@@config.ack.dialog.column.ackStatus">
                  <ng-template #acqStatusRenderer let-data>
                    <div [ngSwitch]="data['ackStatus']">
                      <div class="bg-success p-1 rounded d-flex justify-content-center align-items-center"
                           *ngSwitchCase="ackStatus[ackStatus.ACK]">
                        <!-- ackStatus.ACK -->
                        <i class="fas fa-check-circle mr-1"></i>Acquitté
                      </div>
                      <div class="bg-secondary text-dark p-1 rounded d-flex justify-content-center align-items-center"
                           *ngSwitchCase="ackStatus[ackStatus.NO_ACK]">
                        <!-- ackStatus.NO_ACK -->
                        <i class="fas fa-clock mr-1"></i>Non acquitté
                      </div>
                      <div class="bg-danger p-1 rounded d-flex justify-content-center align-items-center"
                           *ngSwitchCase="ackStatus[ackStatus.ERROR]">
                        <!-- ackStatus.ERROR -->
                        <i class="fas fa-times-circle mr-1"></i>En erreur
                      </div>
                      <div class="bg-primary p-1 rounded d-flex justify-content-center align-items-center"
                           *ngSwitchCase="ackStatus[ackStatus.PENDING]">
                        <!-- ackStatus.PENDING -->
                        <i class="fas fa-sync-alt fa-spin mr-1"></i>En cours
                      </div>
                    </div>
                  </ng-template>
                </e-column>
                <e-column field="targetNodes" headerText="Noeuds impactés" width="170"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>
      </ng-template>
      <ng-template #footerTemplate>
        <ng-container *ngIf="!allAck; else allAckTmpl">
          <div>
            <button #progressBtn ejs-progressbutton id="Button1" class="e-control e-btn e-primary" data-ripple="true"
                    [duration]="30000"
                    [disabled]="!logConfigs || logConfigs.length === 0"
                    (click)="confirm()">
              <i class="fas fa-check"></i>
              <ng-container i18n="@@dialog.validateAll"> Tout acquitter</ng-container>
            </button>
            <button id="Button2" class="e-control e-btn" data-ripple="true" (click)="dialog.hide()">
              <i class="fas fa-times"></i>
              <ng-container i18n="@@dialog.cancel"> Annuler</ng-container>
            </button>
          </div>
        </ng-container>
        <ng-template #allAckTmpl>
          <button id="Button2" class="e-control e-btn" data-ripple="true" (click)="dialog.hide()">
            <i class="fas fa-times"></i>
            <ng-container i18n="@@dialog.close">Fermer</ng-container>
          </button>
        </ng-template>
      </ng-template>
    </ejs-dialog>
  `,
  styleUrls: ['./config-ack-dialog.component.css']
})
export class ConfigAckDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dialog') dialog: DialogComponent;
  @ViewChild('progressBtn') progressBtn: ProgressButtonComponent;
  @ViewChild('grid') grid: GridComponent;

  public logConfigs: LogConfig[] | undefined;

  public ackStatus = AckStatus;
  public action = Action;

  private ackConfigSubscription: Subscription;
  public allAck: boolean;
  private logConfigAcqStatusChangedSubscription?: Subscription;

  private currentUser = this.tools.getCurrentUser();
  public userPeutAcquitterLaConfig = this.currentUser?.hasAuthority('acq_config');

  constructor(private configChangeService: ConfigChangeService,
              private tools: ToolsService) {
  }

  ngAfterViewInit(): void {
    this.dialog.addEventListener('close', () => {
      // this.progressBtn?.progressComplete();
      this.logConfigs = [];
      this.logConfigAcqStatusChangedSubscription?.unsubscribe();
    });
  }

  public show(): void {
    if (!this.userPeutAcquitterLaConfig) {
      return;
    }

    this.allAck = false;

    this.logConfigAcqStatusChangedSubscription = this.configChangeService.watchAcqStatusChanged().subscribe(ackStatusMsgs => {
      ackStatusMsgs.forEach(ackStatusMsg => {
        const target = this.logConfigs?.find(unAckConfig => unAckConfig.id === ackStatusMsg.id);

        if (target) {
          target.ackStatus = ackStatusMsg.ackStatus;
          this.grid.refresh();

          // Gestion du cas où tous les LogConfig ont été acquittés
          if (this.logConfigs?.map(unAckConfig => unAckConfig.ackStatus).every(ackStatus => ackStatus === AckStatus[AckStatus.ACK])) {
            this.allAck = true;
          } else {
            this.progressBtn?.progressComplete();
          }
        }
      });
    });

    this.dialog.show();

    this.configChangeService.getChanges().subscribe(logConfigs => {
      this.logConfigs = logConfigs;
    });
  }

  public confirm(): void {
    if (!this.logConfigs || this.logConfigs.length === 0) {
      return;
    }

    const unAckConfigs = this.logConfigs.filter(logConfig => logConfig.ackStatus !== AckStatus[AckStatus.ACK]);

    unAckConfigs.forEach(unAckConfig => unAckConfig.ackStatus = AckStatus[AckStatus.PENDING] as keyof typeof AckStatus);
    this.grid.refresh();

    this.configChangeService.ackConfig(unAckConfigs.map(unAckLogConf => unAckLogConf.id)).subscribe();
  }

  ngOnDestroy(): void {
    this.ackConfigSubscription?.unsubscribe();
  }

}
