import {EnvironmentData} from './environment-data';

export const environment: EnvironmentData = {
  production: true,
  applicationName: 'NeoLog',

  applicationId: 'neolog',

  storageKey: 'neolog',

  syncfusionLicenceKey: 'ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViUX9bcXVQQmhZU0I=',
  
  api: {
    prefix: '/api'
  },

  apiGateway: {
    prefix: '/api-gateway'
  },

  ws: {
    protocol: 'wss',
    prefix: '/ws',
    userQueue: '/user/queue',
    topic: '/topic'
  },

  router: {
    login: '/login',
    logout: '/logout',
    home: '/'
  },

  log: {
    level: {
      root: 'info'
    }
  },

  security: {
    tokenRefreshInterval: (10 * 60_000)
  },

  app: {}
};
