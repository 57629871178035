import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Column, ColumnModel, Grid } from '@syncfusion/ej2-angular-grids';

/**
 * Pour créer un renderer custom il faut :
 * 1. Définir un ng-template dans la balise tlgpro-grid-syncfusion, et lui donner un selecteur (ex: #test)
 * 2. Pointer sur ce template grâce à un @ViewChild de type NgModel;
 * 3. Définir une map de renderer customs de type Map<string, NgModel>
 * Dans la méthode ngAfterViewInit du composant :
 * 4. Ajouter le ng-template à cette map, la clé doit être la même que le selecteur du template (ex: 'test' si #test)
 * 5. Appeler la méthode addRenderers(customMap) en lui donnant en paramètre la map précedemment créee
 * Répéter les points 1,2,4 pour ajouter d'autres renderers custom sur la même tlgpro-grid-syncfusion
 * NOTE : Il n'y a pas de surcharge des renderers génériques, un warning est affiché dans la console si l'on essaye de surcharger un renderer existant
 */
@Component({
  selector: 'tlgpro-renderer',
  template: `
    <ng-template let-data #ouiNon>
      <span *ngIf="data[data.column.field] === 1 || data[data.column.field] === true" style="color: var(--success-color, green);">Oui</span>
      <span *ngIf="data[data.column.field] === 0 || data[data.column.field] === false"style="color: var(--error-color, red);">Non</span>
    </ng-template>

    <ng-template let-data #ouiNonPicto>
      <i *ngIf="data[data.column.field] === 1 || data[data.column.field] === true" class="fas fa-check" style="font-size: 1.2rem; color:var(--success-color, green)"></i>
      <i *ngIf="data[data.column.field] === 0 || data[data.column.field] === false" class="fas fa-times" style="font-size: 1.2rem; color:var(--error-color, red)"></i>
    </ng-template>

    <ng-template let-data #nonOui>
      <span *ngIf="data[data.column.field] === 1 || data[data.column.field] === true" style="color: var(--error-color, red);">Oui</span>
      <span *ngIf="data[data.column.field] === 0 || data[data.column.field] === false"style="color: var(--success-color, green);">Non</span>
    </ng-template>

    <ng-template let-data #nonOuiPicto>
      <i *ngIf="data[data.column.field] === 1 || data[data.column.field] === true" class="fas fa-check" style="font-size: 1.2rem; color:var(--error-color, red)"></i>
      <i *ngIf="data[data.column.field] === 0 || data[data.column.field] === false" class="fas fa-times" style="font-size: 1.2rem; color:var(--success-color, green)"></i>
    </ng-template>

    <ng-template let-data #pourcentage>
      {{ data[data.column.field] }} %
    </ng-template>

    <ng-template let-data #color>
      <div [style.backgroundColor]="data[data.column.field]">&nbsp;</div>
    </ng-template>

    <ng-template let-data #shortDate>
      <span title="{{ data[data.column.field] | date:'long' }}">{{ data[data.column.field] | date:'short' }}</span>
    </ng-template>

    <ng-template let-data #mediumDate>
      <span title="{{ data[data.column.field] | date:'long' }}">{{ data[data.column.field] | date:'medium' }}</span>
    </ng-template>

    <ng-template let-data #longDate>
      {{ data[data.column.field] | date:'long' }}
    </ng-template>

    <ng-template let-data #shortDateMediumTime>
      <span title="{{ data[data.column.field] | date:'long' }}">{{ data[data.column.field] | date:'shortDate' }} {{ data[data.column.field] | date:'mediumTime' }}</span>
    </ng-template>
  `
})
export class RendererComponent implements AfterViewInit {
  @ViewChild('ouiNon') private ouiNon: NgModel;
  @ViewChild('ouiNonPicto') private ouiNonPicto: NgModel;
  @ViewChild('nonOui') private nonOui: NgModel;
  @ViewChild('nonOuiPicto') private nonOuiPicto: NgModel;
  @ViewChild('pourcentage') private pourcentage: NgModel;
  @ViewChild('color') private color: NgModel;
  @ViewChild('shortDate') private shortDate: NgModel;
  @ViewChild('mediumDate') private mediumDate: NgModel;
  @ViewChild('longDate') private longDate: NgModel;
  @ViewChild('shortDateMediumTime') private shortDateMediumTime: NgModel;

  renderers: Map<string, NgModel> = new Map<string, NgModel>();

  customsRenderers: Map<string, NgModel>;

  ngAfterViewInit(): void {
    this.renderers.set('ouiNon', this.ouiNon);
    this.renderers.set('ouiNonPicto', this.ouiNonPicto);
    this.renderers.set('nonOui', this.nonOui);
    this.renderers.set('nonOuiPicto', this.nonOuiPicto);
    this.renderers.set('pourcentage', this.pourcentage);
    this.renderers.set('color', this.color);
    this.renderers.set('shortDate', this.shortDate);
    this.renderers.set('mediumDate', this.mediumDate);
    this.renderers.set('longDate', this.longDate);
    this.renderers.set('shortDateMediumTime', this.shortDateMediumTime);
  }

  render(columns: ColumnModel[], grid: Grid): void {
    grid.getColumns().forEach((column: Column) => {
      if (column.template != null && typeof column.template === 'string') {
        if (this.customsRenderers && this.customsRenderers.get(column.template)) {
          column.template = this.customsRenderers.get(column.template) as any;
        } else if (this.renderers.get(column.template)) {
          column.template = this.renderers.get(column.template) as any;
        }
      }
    });

    // columns.forEach((item: ColumnModel) => {
    //   if (item.template != null && typeof item.template === 'string') {

    //     let column = null;
    //     if (item.uid) {
    //       column = grid.getColumnByUid(item.uid);
    //     } else if (item.field) {
    //       column = grid.getColumnByField(item.field);
    //     } else if (item.index) {
    //       column = grid.getColumnByIndex(item.index);
    //     }

    //     if (column != null) {
    //       if (this.customsRenderers && this.customsRenderers.get(item.template)) {
    //         column.template = this.customsRenderers.get(item.template) as any;
    //       } else if (this.renderers.get(item.template)) {
    //         column.template = this.renderers.get(item.template) as any;
    //       }
    //     }
    //   }
    // });
  }

  setCustomRenderers(customRenderers: Map<string, NgModel>): void {
    customRenderers.forEach((value: NgModel, key: string) => {
      if (this.renderers.has(key)) {
        console.warn(`Le renderer \"${key}\" est déjà défini dans la librairie.`);
      }
    });

    this.customsRenderers = customRenderers;
  }
}
