import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'tlgpro-header-burger-icon',
  template: `
    <div class="burger-icon [&>*]:bg-gray-400" (click)="toggle.next()" [ngClass]="{'open': menuState}">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  `,
  styleUrls: ['./header-burger-icon.component.css']
})
export class HeaderBurgerIconComponent implements OnInit {
  @Input() public menuState?: boolean;
  @Output() public toggle = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
