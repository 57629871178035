import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {LocalStorageService} from '@tlgpro/util';

@Component({
  selector: 'layout-header',
  template: `
    <header class="h-[var(--header-height)] bg-gray-50 border-b flex dark:border-b-gray-600 dark:text-gray-200 dark:bg-gray-700 justify-between">
      <div class="px-2 py-4 flex flex-row items-center justify-between">
        <div>
          <button type="button" (click)="toggleSidebar()"
                  class="hidden md:block text-gray-500 dark:text-gray-400 hover:text-gray-800
                   dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-500
                   dark:focus:ring-gray-700 rounded text-sm p-2.5">
            <i class="fa-solid fa-angles-left duration-300 transition-[transform]"
               [ngClass]="{'rotate-180': sidebarState === false}"></i>
          </button>
          <tlgpro-header-burger-icon class="md:hidden" [menuState]="menuState"
                                     (toggle)="toggleMenu()"></tlgpro-header-burger-icon>
        </div>
        <ng-container *ngIf="applicationNameTemplate">
          <ng-container *ngTemplateOutlet="applicationNameTemplate">
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="headerTemplate" class="ml-4 w-full items-center flex justify-between">
        <ng-container *ngTemplateOutlet="headerTemplate">
        </ng-container>
      </div>
      <div class="ml-1 px-2 py-4 flex gap-x-2.5 flex-row items-center justify-between">
        <button type="button" (click)="toggleTheme()"
                *ngIf="!forcedTheme"
                class="text-gray-500 dark:text-gray-400
                hover:text-yellow-400
                dark:hover:text-white
                hover:bg-gray-200 dark:hover:bg-gray-500 dark:focus:ring-gray-700 rounded text-sm p-2.5">
          <i *ngIf="theme === 'light'" class="fa-solid fa-sun"></i>
          <i *ngIf="theme === 'dark'" class="fa-solid fa-moon"></i>
        </button>
        <a routerLink="/logout" class="group
                hover:bg-gray-200 dark:hover:bg-gray-500 dark:focus:ring-gray-700 rounded text-sm p-2.5">
          <i class="text-gray-500 dark:text-gray-400
          group-hover:text-blue-500 dark:group-hover:text-blue-400
          fa-solid fa-right-from-bracket"></i>
        </a>
      </div>
    </header>
  `,
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() public headerTemplate?: TemplateRef<any>;
  @Input() public applicationNameTemplate?: TemplateRef<any>;

  @Output() public darkThemeChange = new EventEmitter<boolean>();
  @Output() public toggleSidebarChange = new EventEmitter<void>();
  @Output() public toggleMenuChange = new EventEmitter<void>();

  @Input() public defaultTheme?: 'light' | 'dark';
  @Input() public forcedTheme?: 'light' | 'dark';
  @Input() public storageKey?: string;

  public theme?: 'light' | 'dark';

  public sidebarState?: boolean;
  public menuState?: boolean;

  private readonly colorThemeKey = 'color-theme';

  constructor(private localStorageService: LocalStorageService) {
  }

  get savedTheme(): 'light' | 'dark' {
    return (this.localStorageService.getItem(this.colorThemeKey) as 'light' | 'dark') || this.defaultTheme || 'dark';
  }

  set savedTheme(theme: 'light' | 'dark') {
    this.localStorageService.setItem(this.colorThemeKey, theme);
  }

  public toggleTheme(): void {
    this.theme = this.theme === 'dark' ? 'light' : 'dark';

    this.savedTheme = this.theme;

    this.darkThemeChange.next(this.theme === 'dark');
  }

  public toggleSidebar(): void {
    this.toggleSidebarChange.next();
  }

  public toggleMenu(): void {
    this.toggleMenuChange.next();
  }

  ngOnInit(): void {
    this.theme = this.forcedTheme || this.savedTheme;
    this.darkThemeChange.next(this.theme === 'dark');
  }
}
