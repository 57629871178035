import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiDataManager, ApiService, SimpleListAdaptor, SyncfusionSpringAdaptor} from '@tlgpro/api-syncfusion';

import {
  Column,
  ColumnModel,
  CommandClickEventArgs,
  CommandModel,
  EditEventArgs,
  SortEventArgs,
  SortSettingsModel
} from '@syncfusion/ej2-angular-grids';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {DataManager} from '@syncfusion/ej2-data';
import {CustomEditManager, CustomMultiSelect, EditManagerTools, GridSyncfusionComponent} from '@tlgpro/grid-syncfusion';
import {ToastSyncfusionService} from '@tlgpro/toast-syncfusion';
import {EnvironmentData} from '../../models/tools/environment-data';
import {Role, User} from '../../../auth';
import {ChangePasswordFormComponent} from '../../components/change-password-form/change-password-form.component';
import {AdministrationService} from '../../services/administration.service';

@Component({
  template: `
    <div class="d-flex flex-column h-100">
      <h1 class="text-center"><i class="fas fa-users-cog"></i><ng-container i18n="@@administration.page.users">Paramétrage des utilisateurs</ng-container></h1>
      <div class="overflow-auto flex-grow-1">
        <tlgpro-grid-syncfusion #grid (commandClick)='commandClick($event)' clipMode="EllipsisWithTooltip" [dataSource]="data" [columns]="columns" [sortSettings]="sortSettings"
                                (actionFailure)="actionFailure($event)"></tlgpro-grid-syncfusion>
      </div>

      <ejs-dialog #dialog showCloseIcon='true' i18n-header="@@administration.page.changePasswordDialog.title" header="Modification du mot de passe" [visible]='false' width='500' [isModal]="true" (close)="form.reset()">
        <ng-template #content>
          <tlgpro-change-password-form #form></tlgpro-change-password-form>
        </ng-template>

        <ng-template #footerTemplate>
          <button type="submit" form="form" class="e-control e-btn e-lib e-primary e-flat" i18n="@@app.form.button.save" (click)="submit()">Sauvegarder</button>
          <button type="button" class="e-control e-btn e-lib e-flat" i18n="@@app.form.button.cancel" (click)="dialog.hide()">Annuler</button>
        </ng-template>
      </ejs-dialog>
    </div>
  `,
  styleUrls: ['./utilisateurs.page.css', '../../admin.css']
})
// tslint:disable-next-line: component-class-suffix
export class UtilisateursPage implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: GridSyncfusionComponent;
  @ViewChild('dialog') dialog: DialogComponent;
  @ViewChild('form') form: ChangePasswordFormComponent;

  data: ApiDataManager;
  roles: ApiDataManager;

  modeAuthentification = new DataManager([
    {value: 'DB', libelle: $localize`:@@administration.users.loginMode.database:Base de données`},
    {value: 'LDAP', libelle: $localize`:@@administration.users.loginMode.ldap:LDAP`},
    {value: 'BOTH', libelle: $localize`:@@administration.users.loginMode.ldapAndDatabase:LDAP + Base de données`}
  ]);

  statut = new DataManager([
    {value: 0, libelle: $localize`:@@administration.users.status.enabled:Activé`},
    {value: 1, libelle: $localize`:@@administration.users.status.disabled:Désactivé`}
  ]);

  columns: ColumnModel[];

  sortSettings: SortSettingsModel = {
    columns: [{field: 'username', direction: 'Ascending'}]
  };

  private changePasswordCommand: CommandModel = {
    title: $localize`:@@administration.users.changePasswordButton:Modifier le mot de passe`,
    buttonOption: {
      iconCss: 'fas fa-key'
    }
  };

  private currentEditUserId: number;

  private itemTemplateRole = '<span>${name}</span>';

  constructor(
    private api: ApiService,
    private adminService: AdministrationService,
    private toast: ToastSyncfusionService,
    @Inject('env') private environment: EnvironmentData
  ) {
  }

  ngOnInit(): void {

    this.data = this.api.getDataManager({
      url: `${this.environment.api.prefix}/user`,
      adaptor: new SyncfusionSpringAdaptor()
    }, undefined, undefined, this.failureCallback);

    this.roles = this.api.getDataManager({
      url: `${this.environment.api.prefix}/role`,
      adaptor: new SimpleListAdaptor()
    });

    this.columns = [
      {width: 30, clipMode: 'Clip', textAlign: 'Center', commands: [this.changePasswordCommand]},
      {field: 'id', headerText: $localize`:@@administration.users.id:ID`, width: 50, isPrimaryKey: true, visible: false},
      {field: 'username', headerText: $localize`:@@administration.users.username:Nom d'utilisateur`, width: 70, validationRules: {required: true}},
      {field: 'firstname', headerText: $localize`:@@administration.users.firstname:Prénom`, width: 70},
      {field: 'lastname', headerText: $localize`:@@administration.users.lastname:Nom`, width: 70},
      {field: 'email', headerText: $localize`:@@administration.users.email:Mail`, width: 70, validationRules: {email: true}},
      // role : names, edit
      {
        field: 'roles', headerText: $localize`:@@administration.users.roles:Roles`, valueAccessor: this.rolesValueAccessor,
        allowFiltering: true, allowSorting: true,
        customAttributes: {colonne: 'roleName', visibleEdition: false, visibleTable: true}
      },
      {
        field: 'roles', headerText: $localize`:@@administration.users.rolesId:ID Roles`,
        visible: false, allowSearching: false,
        customAttributes: {visibleEdition: true, visibleTable: false, itemTemplate: this.itemTemplateRole, placeholder: $localize`:@@administration.users.roles:Roles`, value: 'roles'},
        edit: new CustomEditManager(this.roles, new CustomMultiSelect()).editparams
      },
      {
        field: 'enabled',
        headerText: $localize`:@@administration.users.isEnabled:Activé`,
        width: 110,
        allowFiltering: true,
        allowSorting: true,
        displayAsCheckBox: true,
        textAlign: 'Center',
        editType: 'booleanEdit'
      },
    ];
  }

  ngAfterViewInit(): void {
    this.grid.instance.addEventListener('actionBegin', (e: EditEventArgs | SortEventArgs) => this.actionBegin(e));
  }

  private failureCallback = () => {
    this.grid.instance.trigger('actionFailure');
  }

  private rolesValueAccessor(field: string, data: User): string {
    return data.roles.map((role: Role) => role.name).join(', ');
  }

  actionBeginEdit(): void {
    for (const col of this.grid.columns) {
      EditManagerTools.switchColumnVisibility((col as Column), 'visibleEdition');
    }
  }

  actionBegin(args: EditEventArgs | SortEventArgs): void {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.actionBeginEdit();
    } else if (args.requestType === 'sorting') {
      // this.actionBeginSort(args as SortEventArgs);
    } else if (args.requestType === 'save') {
      for (const col of this.grid.columns) {
        EditManagerTools.switchColumnVisibility((col as Column), 'visibleTable');
      }
    }
  }

  commandClick(e: CommandClickEventArgs): void {
    switch (e.commandColumn?.buttonOption?.iconCss) {
      case this.changePasswordCommand.buttonOption?.iconCss: {

        // Récupération de l'ID en cours d'édition
        if (e.rowData?.hasOwnProperty('id')) {
          // tslint:disable-next-line: no-any
          this.currentEditUserId = (e.rowData as any).id;
          this.dialog.show();
        }

        break;
      }
    }

  }

  // tslint:disable-next-line: no-any
  actionFailure(e: any): void {
    if (e && e.error) {
      if (e.error.error && e.error.error instanceof XMLHttpRequest) {
        this.api.onError(e.error.error.statusText, e.error.error.status);
      }
    }
  }

  submit(): void {
    if (this.form.isValid()) {

      this.adminService.updatePassword({
        idUser: this.currentEditUserId,
        password: this.form.getValue()
      }).subscribe(
        () => {
          this.toast.show({
            title: $localize`Succès`,
            cssClass: 'e-toast-success',
            content: $localize`Mot de passe modifié`
          });

          this.dialog.hide();
        }
      );
    }
  }
}
