import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserCompatibilityComponent } from './components/browser-compatibility/browser-compatibility.component';

@NgModule({
  declarations: [BrowserCompatibilityComponent],
  imports: [
    CommonModule
  ],
  exports: [BrowserCompatibilityComponent]
})
export class LoadingModule { }
