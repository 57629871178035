import {ServiceType} from './service-type';
import {Type} from 'class-transformer';

export class Service {
  id: number;
  name: string;

  @Type(() => ServiceType)
  serviceType: ServiceType;
}
