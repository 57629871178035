import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {EnvironmentData} from '../admin/models/tools/environment-data';
import {StoreInterface} from '@tlgpro/api-syncfusion';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private auth: AuthService,
              @Inject('env') private environment: EnvironmentData,
              @Inject('store') private store: StoreInterface) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (req.url.indexOf(this.environment.api.prefix) !== -1) {

      // Check if there is a token
      const authReq = !this.store.selectSnapshot('accessToken') ? req : req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.store.selectSnapshot('accessToken')}`)
      });

      return next.handle(authReq).pipe(tap(() => { },
        (error) => {
          if ((error instanceof HttpErrorResponse) && (error.status === 401)) {

            // Disconnect with the Auth service
            this.router.navigate([this.environment.router.logout]);

          }

        }));

    } else {
      return next.handle(req);
    }

  }

}
