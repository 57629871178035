import {Inject, Injectable} from '@angular/core';
import {EnvironmentData} from '../models/environment-data';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    @Inject('env') private environment: EnvironmentData
  ) {
  }

  /**
   * Returns the current value associated with the given key, or null if the given key does not exist.
   */
  getItem(key: string): string | null {
    return localStorage.getItem(`${this.environment.applicationId}.${key}`);
  }

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   *
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set. (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  setItem(key: string, value: string): void {
    localStorage.setItem(`${this.environment.applicationId}.${key}`, value);
  }

  /**
   * Removes all key/value pairs, if there are any.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  clear(): void {

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if(key != null && key.startsWith(`${this.environment.applicationId}.`)){
        localStorage.removeItem(key);
      }
    }

  }

  /**
   * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
   */
  removeItem(key: string): void {
    localStorage.removeItem(`${this.environment.applicationId}.${key}`);
  }

}
