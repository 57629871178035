import {Component, OnDestroy, OnInit} from '@angular/core';
import {Navigation} from '../../../../_nav';
import {environment} from '../../../../../environments/environment';
import {Subscription, timer} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, startWith} from 'rxjs/operators';
import {Store} from '@core/store';
import {RxStompService} from '../../../../core/rxstomp/rxstomp.service';
import {ToolsService} from '@core/tools';
import {LoginService} from '../../../authentification/services/login.service';

@Component({
  template: `
    <ng-template #applicationNameTemplate>
      <app-logo-application></app-logo-application>
    </ng-template>

    <ng-template #routerOutletTemplate>
      <router-outlet></router-outlet>
    </ng-template>

    <ng-template #headerTemplate>
      <app-breadcrumb [title]="pageName"></app-breadcrumb>
      <div>
        <app-config-ack-button *ngIf="userPeutAcquitterLaConfig"
                               (btnClick)="ackConfigDialog.show()"></app-config-ack-button>
        <app-config-ack-dialog #ackConfigDialog></app-config-ack-dialog>
      </div>
    </ng-template>

    <tlgpro-layout [nav]="navigation"
                   [headerTemplate]="headerTemplate"
                   [applicationNameTemplate]="applicationNameTemplate"
                   [routerOutletTemplate]="routerOutletTemplate"
                   [storageKey]="storageKey"></tlgpro-layout>
  `,
  styleUrls: ['./application.page.css']
})
export class ApplicationComponent implements OnInit, OnDestroy {
  public navigation = Navigation.get(this.store.selectSnapshot('user'));
  public user = this.store.selectSnapshot('user');
  public pageName: string | null;

  private refreshTokenSubscription: Subscription;
  private loginSubscription: Subscription;
  private pageNameSubscription: Subscription;
  private routerEventSubscription: Subscription;

  private currentUser = this.tools.getCurrentUser();
  public userPeutAcquitterLaConfig = this.currentUser?.hasAuthority('acq_config');

  public storageKey = environment.storageKey;

  constructor(
    private store: Store,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stomp: RxStompService,
    private tools: ToolsService
  ) {
  }

  ngOnInit(): void {
    this.stomp.connect();
    
    this.refreshTokenSubscription = timer(0, environment.security.tokenRefreshInterval).subscribe(() => {
      // Si l'utilisateur est connecté, on met à jour le token
      if (this.store.selectSnapshot('isAuthenticated')) {
        this.loginSubscription = this.loginService.refreshToken().subscribe();
      }
    });

    this.pageNameSubscription = this.store.select('pageName').subscribe(
      (pageName: string | null) => {
        this.pageName = pageName;
      });

    this.routerEventSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.router),
      map(() => {
        const title: string[] = [];
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.snapshot.data.title) {
            title.push(child.snapshot.data.title);
          }
          if (child.firstChild) {
            child = child.firstChild;
          } else {
            return title;
          }
        }
        return title;
      })
    ).subscribe((data: string[]) => {
      if (data) {
        this.store.dispatch({pageName: data.join(' / ')});
      }
    });
  }

  ngOnDestroy() {
    this.stomp.close();

    this.refreshTokenSubscription?.unsubscribe();
    this.loginSubscription?.unsubscribe();
    this.pageNameSubscription?.unsubscribe();
    this.routerEventSubscription?.unsubscribe();
  }
}
