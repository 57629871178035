import {Injectable} from '@angular/core';
import {merge, Observable} from 'rxjs';
import {RxStompService} from '../../../core/rxstomp/rxstomp.service';
import {map, take} from 'rxjs/operators';
import {plainToInstance} from 'class-transformer';
import {AckStatus, LogConfig} from '../models/log-config';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigChangeService {
  private readonly UNPUBLISHED_CONFIG_DESTINATION = '/countUnacknowledgedConfig';
  private readonly CONFIG_ACQ_STATUS_CHANGED_DESTINATION = '/logConfigAcqStatusChanged';

  constructor(private http: HttpClient, private stomp: RxStompService) {
  }

  public getChanges(): Observable<LogConfig[]> {
    return this.http.get(`${environment.api.prefix}/configuration/getChanges`).pipe(
      map((r: unknown) => plainToInstance(LogConfig, r as LogConfig[])
      ));
  }

  public watchCountChanges(): Observable<number> {
    return merge(this.stomp.watch(`${environment.ws.topic}${this.UNPUBLISHED_CONFIG_DESTINATION}`),
      this.stomp.watch(`${environment.ws.userQueue}${this.UNPUBLISHED_CONFIG_DESTINATION}`).pipe(take(1))).pipe(
      map((data: unknown) => data as number)
    );
  }

  public watchAcqStatusChanged(): Observable<{ id: number, ackStatus: keyof typeof AckStatus }[]> {
    return this.stomp.watch(`${environment.ws.topic}${this.CONFIG_ACQ_STATUS_CHANGED_DESTINATION}`).pipe(
      map((data: unknown) => data as { id: number, ackStatus: keyof typeof AckStatus }[])
    );
  }

  public ackConfig(idsToAck: number[]): Observable<unknown> {
    return this.http.post(`${environment.api.prefix}/configuration/ack`, idsToAck);
  }
}
