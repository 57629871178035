import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigAckButtonComponent} from './components/config-ack-button/config-ack-button.component';
import {ConfigAckDialogComponent} from './components/config-ack-dialog/config-ack-dialog.component';
import {ButtonModule} from '@syncfusion/ej2-angular-buttons';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {LoadingModule} from '@ui/loading';
import {GridModule, PageService} from '@syncfusion/ej2-angular-grids';
import {ProgressButtonModule} from '@syncfusion/ej2-angular-splitbuttons';

@NgModule({
  declarations: [
    ConfigAckButtonComponent,
    ConfigAckDialogComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    LoadingModule,
    GridModule,
    ProgressButtonModule
  ],
  exports: [
    ConfigAckButtonComponent,
    ConfigAckDialogComponent
  ],
  providers: [PageService]
})
export class LogConfigModule {
}
