import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class I18nInterceptor implements HttpInterceptor {

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // Add current locale to HTTP Header
    const i18nReq = req.clone({
      headers: req.headers.set('Accept-Language', this.locale)
    });

    return next.handle(i18nReq);

  }

}
