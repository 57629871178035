import {Component, Input} from '@angular/core';
import {INavData} from '../../../../models';
import {Router} from "@angular/router";

@Component({
  selector: 'app-sidebar-nav',
  template: `
    <div *ngFor="let nav of navs" [ngClass]="{'mt-auto': nav.bottom}">
      <a *ngIf="!nav.children; else childTmpl"
         class="group block py-2
         hover:bg-blue-500
         hover:underline hover:text-gray-50
         dark:hover:bg-blue-400 dark:hover:text-gray-50
         focus:outline-none focus:shadow-outline
         pl-[10px]"
         routerLinkActive="border-l-[5px] !pl-[5px] font-semibold border-blue-500 dark:border-blue-400 bg-white dark:bg-gray-800"
         [routerLink]="nav.url"><i routerLinkActive="text-blue-500 dark:text-blue-400"
                                   [class]="'text-gray-400 group-hover:text-gray-50 dark:group-hover:text-gray-50 mr-2 ' + nav.icon"></i>{{nav.name}}
      </a>
      <ng-template #childTmpl>
        <div class="pl-1">
          <h2 class="group pr-2 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-800">
            <button type="button"
                    class="flex items-center justify-between w-full px-1 py-2 text-left"
                    (click)="nav.collapsed = !nav.collapsed">
          <span>
            <i [class]="'text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50 mr-2 ' + nav?.icon"></i>
            {{nav?.name}}
          </span>
              <i class="text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50 fas" [ngClass]="{'fa-angle-down': nav.collapsed, 'fa-angle-up': !nav.collapsed}"></i>
            </button>
          </h2>
          <div class="transition-[height] duration-300 overflow-hidden bg-gray-400 bg-opacity-10 dark:bg-black dark:bg-opacity-10
      shadow-[-4px_0px_0px_0px_rgba(255,255,255,0.2)] dark:shadow-[-4px_0px_0px_0px_rgba(0,0,0,0.2)]"
               [ngClass]="{'h-0': nav.collapsed}">
            <app-sidebar-nav [navs]="nav?.children"></app-sidebar-nav>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./sidebar-nav.component.css']
})
export class SidebarNavComponent {

  public _navs?: INavData[];

  @Input()
  public set navs(navs: INavData[] | undefined) {
    navs?.forEach(nav => {
      nav.collapsed = nav?.collapsed !== undefined ? nav?.collapsed : true;

      if (this.router.url.includes(nav.url as string)) {
        nav.collapsed = false;
      }
    });

    this._navs = navs;
  }

  public get navs(): INavData[] | undefined {
    return this._navs;
  }

  constructor(private router: Router) {
  }

}
