import { UrlAdaptor } from '@syncfusion/ej2-data';

export class SyncfusionSpringAdaptor extends UrlAdaptor {
    public processResponse(data: any): object {
        if (data.content && data.totalElements) {
            return {result: data.content, count: data.totalElements };
        } else {
            return data;
        }
    }
}
